.media-box {
    border: 2px solid #222;
    border-radius: 10px;
    flex-basis: 0;
    flex-grow: 1;
    min-width: 280px;
    min-height: 200px;
    text-align: left;
    margin: 10px 20px;
    padding: 0;
    background-position: center;
    background-size: cover;
    box-shadow: 0px 0px 6px #AAA;
}

.media-column {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-height: 200px;
    padding: 10px 20px;
    border-radius: 9px;
    background-color: rgba(200,200,200,0.7);
}


.media-column .content-row {
    justify-content: flex-start;
}

@media only screen and (max-width: 450px) {
    .media-box {
        min-width: 175px !important;
        min-height: 0 !important;
    }
    .media-column {
        min-height: 0 !important;
    }
}

.media-box .star {
    margin-right: 5px;
}
