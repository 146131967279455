.content-block {
    border-radius: 20px;
    margin: 10px 20px;
    text-align: center;
    padding: 40px 10px;
    display: block;
    box-shadow: 0px 0px 6px #444;
    /* width: 100%; */
}

.content-block.large {
    margin-bottom: 20px;
}

.content-block.slim {
    padding: 10px;
    line-height: 1;
}

.content-block.slim > * {
    line-height: 1;
    margin: 0;
}

.content-block.small {
    display: inline-block;
    min-width: 225px;
    margin: 10px;
}

.content-block.small .content-block-title h3 {
    font-size: 1.9rem;
}

.content-block.medium {
    display: inline-block;
    margin: 10px;
    min-width: 325px;
}

.content-block.medium .content-box {
    max-width: 450px;
}

.content-block.small ul, .content-block.medium ul {
    padding-left: 20px;
}

@media only screen and (max-width: 600px) {
    .content-block-title h3 {
        font-size: 2rem !important;
    }
}

@media only screen and (max-width: 450px) {
    .content-block-title h3 {
        font-size: 1.6rem !important;
    }
    .content-box {
        text-align: left !important;
        padding: 10px !important;
        /* font-size: 0.9rem !important; */
    }
    .media-box {
        padding: 0 !important;
    }
    .content-block-container {
        margin: 0 !important;
    }
    .content-column {
        margin: 0 !important;
    }
    .content-box ul {
        padding-left: 20px;
    }
    .content-block {
        margin: 10px !important;
    }
    .content-block.medium {
        min-width: 225px;
    }
    .right-large-card {
        min-width: 250px !important;
    }
}

@media only screen and (max-width: 350px) {
    .content-box * {
        padding: 0.2em;
    }
}

.content-block a {
	color: #333;
	font-weight: bold;
	text-decoration: none;
}

.content-block a:hover {
	text-decoration: underline;
}

.content-block p {
    line-height: 1.4;
    margin: 0;
}

.content-block li {
    line-height: 1.4;
}

.content-block ul {
    margin: 5px 0;
    list-style-type: square;
}

.content-block-title h3 {
    font-family: 'Roboto Mono', monospace;
	font-size: 3rem;
	text-align: center;
    margin: 0;
    margin-bottom: 30px;
	color: rgb(40,150,160);
}

.content-box {
    display: inline-block;
    min-width: 50px;
    max-width: 600px;
    text-align: justify;
    padding: 10px 20px;
    font-size: 1.1rem;
}

.small p + p {
    padding-top: 20px;
}

.content-block.small .content-box {
    max-width: none;
}

.content-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;

    -ms-flex-wrap: wrap;
    flex-wrap: wrap;

    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.content-row.flex-start {
    align-items: flex-start !important;
}

.content-block-container > .content-block {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;

    -ms-flex-preferred-size: 0;
    flex-basis: 0;
}

.content-block-container {
    margin: 0 10px;
    align-items: stretch;
}

.content-column {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;

    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;

    -ms-flex-pack: distribute;
    justify-content: space-around;

    margin: 0 10px;
}

/* START TABLE DEFS */

.left-small-card {
    text-align: center !important;
    width: 250px;
}

.left-small-card > *:first-child {
    font-weight: bold;
}

.left-small-card > *:nth-child(3) {
    font-style: italic;
}

.right-large-card {
    min-width: 250px;
}

.right-large-card.stretch-box {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
}


.left-large-card {
    text-align: left;

    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
}

.right-media-card > * {
    width: 200px;
    height: auto;
}

/* END TABLE DEFS */

.spaced-ul {
    margin: -7px 0;
}

.spaced-ul > span {
    margin: 7px 0;
}

.spaced-ul .content-row {
    justify-content: flex-start;
    flex-wrap: nowrap;
}

.spaced-ul p {
    width: 100%;
}

.bullet-icon {
    max-height: 18px;
    padding-right: 10px;
}

.nobr {
    white-space: nowrap;
}
