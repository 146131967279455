
/* START DEFAULT BODY DEFS */

@font-face {
    font-family: 'Source Sans';
    src: url("../fonts/source sans.ttf");
}

@font-face {
	font-family: 'Roboto Mono';
	src: url("../fonts/roboto mono.ttf");
}

body {
	margin: 0;
	font-family: 'Source Sans', Verdana, sans-serif;
}

a {
    color: inherit;
}

#portrait {
    width: 250px;
    height: 250px;
    border-radius: 40%;
    box-shadow: 0px 0px 6px #AAA;
}

#page-container {
    min-height: 100vh;
}

/* END DEFAULT BODY DEFS */
