/* START NAVIGATION BAR */

@media only screen and (max-width: 604px) {
	#menu {
		position: fixed;
		z-index: 6;
	}
	.collapsed + #menu-overlay {
		width: 0;
		height: 0;
	}
	.expanded + #menu-overlay {
		min-width: 100vw;
		min-height: 100vh;
		position: fixed;
		top: 0;
		right: 0;
		background-color: rgba(0,0,0,0.5);
		z-index: 8;
	}
	#menu-container {
		background: #333;
		position: fixed;
		overflow-y: scroll;
		scrollbar-width: none;
		-ms-overflow-style: none;
		top: 0;
		left: 0;
		border-right: 2px solid #411;
		height: 100vh;
		width: 60%;
		margin: 0 0 0 -62%;
		transition: margin 1s;
		z-index: 10;
	}
	#menu-container::-webkit-scrollbar {
		width: 0;
		height: 0;
	}
	#menu-container.expanded {
		margin-left: 0;
	}
	#menu-items {
		margin: 0;
		padding: 0;
		display: flex;
		flex-direction: column;
	}
	#menu li {
		margin: 0;
	    height: 60px;
		display: table;
		float: left;
		text-align: left;
	}
	#menu-button-row {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}
	#menu #icon {
		padding: 5px;
	}
	#expand-icon {
		margin: 15px;
		position: fixed;
		top: 0;
		left: 0;
		cursor: pointer;
	}
	#collapse-icon {
		margin: 15px;
		padding-right: 5px;
		display: initial;
		cursor: pointer;
	}
	#menu a:hover:not(#icon) {
		background: #BBB;
		color: black;

	    -webkit-transition: 0.5s;
		-o-transition: 0.5s;
		transition: 0.5s;
	}
}

@media only screen and (min-width: 605px) {
	#expand-icon, #collapse-icon, #menu-overlay {
		display: none;
	}
	#menu {
		margin: 0;
		padding: 0;
		overflow: visible;
		position: fixed;
		top: 0;
		width: 100%;
		z-index: 2;
	}
	#menu-button-row {
		max-height: 60px;
	}
	#menu-container {
		background: #555;
		margin: 0;
		display: flex;
		justify-content: space-between;
		border-bottom: 1px solid red;
	}
	#menu-items {
		margin: 0;
		padding: 0;
		margin-right: 5%;
		float: right;
	}
	#menu li {
		margin: 0;
	    height: 60px;
		display: table;
		float: left;
		text-align: center;
	}
	#menu.opaque a:hover:not(#icon) {
		background: #BBB;
		color: black;

	    -webkit-transition: 0.5s;
		-o-transition: 0.5s;
		transition: 0.5s;
	}
	#menu.transparent a:hover:not(#icon) {
		border-bottom: 1px solid white;
		transition: 0.5s;
	}
}

svg {
	margin: 0;
	height: 100;
}

#menu #icon {
	background-image: url("../graphics/jk-brackets-64.png");
	background-size: contain;
	background-repeat: no-repeat;
    height: 30px;
    width: 30px;
	margin: 15px;
	display: inline-block;
}

#menu a:not(#icon) {
	text-decoration: none;
	color: white;
	font-size: 18px;
	display: table-cell;
	vertical-align: middle;
	padding: 0 20px;

    -webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	transition: all 0.5s;
}



.drop-box {
	float: left;
	overflow: hidden;
	height: 100%;
}

.drop-content {
	display: none;
	position: absolute;
	min-width: 150px;
	padding-top: 5px;
    top: 60px;
	background: #777;
	border-left: 2px solid red;
}

.drop-content a {
	display: block !important;
	line-height: 2;
}

/* END NAVIGATION BAR */
