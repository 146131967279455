.skill-column-title h3 {
    font-family: 'Roboto Mono', monospace;
	font-size: 18px;
	text-align: center;
    margin: 15px 0;
	color: #555;
    /* width: 100%; */
}

/* START STAR NOTATION */

.skill-box {
    display: inline-block;
    text-align: right;
    /* margin: 10px; */
}

.skill-box:not(.skill-text) > *:nth-child(2) {
    margin-left: 20px;
}

.skill-box p {
    display: inline-block;
}

.skill-text {
    text-align: left;
    max-width: 350px;
}

.skill-text p:first-child {
    font-weight: bold;
}

.skill-box {
    width: 100%;
}

.skill-box.justify {
    text-align: justify !important;
}

/* END STAR NOTATION */
