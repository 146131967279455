.cover-image {
	/* background-image: url('../graphics/chicago-uic-small-2.png'); */
	background-size: cover;
	background-position: top center;
	background-attachment: fixed;
	height: 100%;
	width: 100%;
	position: fixed;
	top: 0;
	z-index: -4;
	min-height: 100vh;
}

.cover-canvas {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    z-index: -4;
}

.overlay {
	background-color: rgba(40, 150, 160, 0.7);
	background-size: cover;
	height: 100%;
	width: 100%;
	position: fixed;
	top: 0;
	z-index: -2;
	min-height: 100vh;
}

#legend-row {
	margin-bottom: 15px;
}

#legend-row > * > * {
    margin: 0 15px;
    color: white;
}

#legend-row p {
	margin: 5px 0;
}

#projects .star {
    border-color: #333;
    margin-right: 5px;
    height: 10px;
    width: 10px;
}
