footer {
    margin-top: auto;
    color: white;
    font-size: 16pt;
    text-align: center;
    height: 100%;
}

svg {
    height: 25px;
    width: 25px;
}
