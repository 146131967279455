form * {
    display: block;
    text-align: left;
}

form label {
    margin: 0 0 2px 20px;
}

form *:not(label) {
    margin: 0 0 10px 20px;
    padding: 4px;
}

form .extend {
    width: 80%;
    max-width: 400px;
}

form input, form textarea {
    font-family: inherit;
    font-size: 14px;
}

form textarea {
    min-height: 80px;
}

form input[type="submit"]:disabled {
    cursor: progress;
}

form {
    margin-left: 10px;
}

@media only screen and (max-width: 450px) {
    form {
        font-size: 14px;
    }
}

/* form option {
    font-size: 11pt;
} */
