.star {
    border: 2px solid gray;
    border-radius: 25%;
    width: 8px;
    height: 8px;
    display: inline-block;
    margin: 0 2px;
}

.star.fill {
    background: #FF4;
}
